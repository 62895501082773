import React from "react";

import { CreationContext } from "./CreationContext";
import { CreationState, CreationActions } from "./types";

export const useCreationState = (): CreationState => {
  const { state } = React.useContext(CreationContext);
  return state;
};

export const useCreationDispatch = (): React.Dispatch<CreationActions> => {
  const { dispatch } = React.useContext(CreationContext);
  return dispatch;
};
