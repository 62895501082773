export const termsText = `
  ## **Terms & Conditions**
  By participating in this Competition (the “Competition”), you confirm that you accept and
agree to these Competition Terms. 

- The Promoter of this Competition is OPPO / OMC Electronics Ltd (“OPPO”).  
- This Competition is only open to New Zealand residents above and is not open to
employees of OPPO, its agents, contractors, consultants, or their families (including de facto
spouses) or to other persons assisting with this Competition.
- This competition will be run at a range of Wellington Phoenix games over the 2023 / 2024 season.  Each game will be a new "competition" and entries can be entered between 8:00 am on the date of relevant games and ends at 11:59 pm on the date of relevant games.
Games are scheduled for 4th November 2023, 25th November 2023, 28 January 2024, 16 March 2024, 29 March 2024, 27 April 2024
- Entrants must attempt the OPPO Speed kick competition by kicking a ball into the kicking
cage and having their score recorded using an onsite radar gun.
  - You may enter multiple times, but our onsite staff reserve the right to limit number of
entries by one individual to ensure everyone who wants to play can .
  - Prizes are awarded as follows. Fastest kick of the day wins first prize. Second
fastest kick wins second prize and 3 rd fastest kick wins third prize.
  - In the event that more than one kicker has the same speed, OPPO will draw a winner
at random as only one prize will be given for each placegetter. A maximum of three
prizes only will be awarded for this competition.    
  - The prizes are as follows:
  - 1 st Prize OPPO Pad Air
  - 2 nd Prize OPPO Enco Air
  - 3 rd Prize - OPPO Blue tooth speaker
  - OPPO Reserves the right to substitute prizes for similar items of equivalent value if
needed.
  - To be eligible to receive a prize entrant must also opt in to receive marketing emails
from OPPO
  - In the event of equipment malfunction before the end of the competition period (for
example if the radar gun malfunctions) and we are unable to measure everyone’s
speed, OPPO reserves the right to change the competition to a random draw in which
the 3 prizes would be drawn at random from entrants throughout the day.
- Winners will be notified either in person or by phone and/or email by 11.59 pm no later than 4 days after the game they have participated in.
- OPPO is not responsible if you have caused, or are responsible for any late, lost or
misdirected entries which result in delay or failure to enter you into the draw.
- All entries to this Competition become the property of OPPO and will not be returned to
the entrants.
- OPPO will provide the prize to each prize winner within two weeks of OPPO‘s
notification of their win and confirmation of the winner’s delivery address.
- Prizes cannot be transferred or exchanged or redeemed for cash. OPPO takes no
responsibility for entries or prizes that are lost or destroyed, including in transit.
- OPPO accepts no responsibility or liability for any prize winner’s enjoyment of any prize
or any other costs and expenses that may be incurred by the prize winner relating to the prize
or the winner’s enjoyment of that prize.
- OPPO will endeavour to notify selected winners by text, phone or email and deliver prizes
by courier, but reserves the right to notify selected winners and deliver prizes by other means.
- OPPO will make reasonable attempts to contact the winner, but if the winner is not able to
be contacted or is unable to redeem the prize in accordance with the terms and conditions within 24 hours from the first contact attempt; the prize will be redrawn in their place without
liability to any person.
- Accepting a prize constitutes the winner&#39;s consent to OPPO to using their name, city/town
of residence, competition entry and/or photographs for Competition or publicity purposes
without compensation.
- The decisions of OPPO on all matters relating to this Competition are final and no
correspondence will be entered into. OPPO reserves the right to verify the validity of an entry
or a winner&#39;s right to redeem the prize in accordance with these terms and conditions. In the
event that the prize is not available due to events outside of OPPO reasonable control, OPPO
may amend the prize offered provided that any substitute will be of an equivalent value to the
prize offered.
- To the extent permitted by law, OPPO excludes any liability it may have to you arising
directly or indirectly out of or in connection with this Competition.
- Any personal information collected as part of this Competition will be held by OPPO in
accordance with its privacy policy. You have the right to access, update and correct such
information.
`;
