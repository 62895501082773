import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import App from "./App";
import { environment, isDev } from "./enviromentHelper";

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (sentryDsn) {
  if (isDev) {
    console.log(`Running with sentry in ${environment} mode`);
  }
  Sentry.init({
    dsn: sentryDsn,
    environment: environment,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occured"}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
