import * as React from "react";
import { Nullable } from "../types";

interface Props {
  label: string;
  name: string;
  register: any;
  icon?: JSX.Element;
  placeholder?: string;
  error?: Nullable<any>;
  style?: React.CSSProperties;
  type?: string;
}

const Input: React.SFC<Props> = ({
  label,
  name,
  icon,
  register,
  placeholder,
  error,
  style,
  type = "string",
}: Props) => {
  const inputRef = React.useRef<Nullable<HTMLInputElement>>(null);

  return (
    <div
      style={style}
      tabIndex={-1}
      onClick={(): void => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      className="flex h-18 md:h-20 relative bg-athensGray rounded-lg px-3 pt-3 sm:pt-4 pb-2 text-black border border-transparent focus-within:border-scienceBlue focus-within:bg-opacity-50 focus-within:text-opacity-50"
    >
      {icon && <div className="ml-2 mr-6">{icon}</div>}
      <div className="flex flex-col flex-1 ">
        <div className="flex self-stretch mb-2">
          <label htmlFor={name} className="block text-label font-bold">
            {label}
          </label>
          {error && (
            <p className="flex-1 flex justify-end text-danger text-label">
              {error.message || "Required"}
            </p>
          )}
        </div>

        <input
          type={type}
          placeholder={placeholder}
          name={name}
          className="mt-1 bg-transparent text-sm md:text-base"
          ref={(e): void => {
            register(e);
            inputRef.current = e;
          }}
        />
      </div>
    </div>
  );
};

export default Input;
