import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { RouteTransition } from "./RouteTransition";
import { AnimatedRoutes } from "./AnimatedRoutes";
import CustomerDetails from "./customerDetails/CustomerDetails";
import KickSpeed from "./kickSpeed/KickSpeed";
import { basepath } from "./common/common";

import { isDev } from "./enviromentHelper";

import Analytics from "react-router-ga";
import Splash from "./splash/splash";
import SuccessScreen from "./successScreen/SuccessScreen";
import MarketingOptIn from "./marketingOptIn/MarketingOptIn";

const AnalyticsWrapper: React.FC<{
  gaId?: string | undefined;
}> = ({ gaId, children }) => {
  if (gaId) {
    return (
      <Analytics id={gaId} debug={isDev}>
        {children}
      </Analytics>
    );
  }

  return <>{children}</>;
};

const topLevelPages = [`/${basepath}/export`, `/${basepath}/checkout/success`];

const CreationFlow: React.FC = () => {
  const gaKey = "";
  return (
    <HelmetProvider>
      <main className="w-full antialiased font-body mobile-screen overflow-auto bg-bgBody overflow-x-hidden">
        <div className="flex items-center justify-center h-full">
          <div className="flex w-full max-w-iphone h-full max-h-lg overflow-hidden">
            <Helmet>
              <meta property="og:url" content={window.location.href} />
              <meta property="og:title" content="" />
              <meta property="og:description" content="" />
              <meta property="og:image" content="" />
              <meta property="og:type" content="website" />
              <title>Speed Kick Challenge</title>
            </Helmet>
            <ToastContainer />
            <Router basename={`/${basepath}`}>
              {!isDev && !topLevelPages.includes(window.location.pathname) && (
                <Redirect to="/" />
              )}

              <AnalyticsWrapper gaId={gaKey}>
                <AnimatedRoutes exitBeforeEnter initial={false}>
                  <RouteTransition exact path="/">
                    <Splash />
                  </RouteTransition>
                  <RouteTransition exact path="/customer-details">
                    <CustomerDetails />
                  </RouteTransition>
                  <RouteTransition exact path="/enter">
                    <MarketingOptIn />
                  </RouteTransition>
                  <RouteTransition exact path="/success">
                    <SuccessScreen />
                  </RouteTransition>
                  <RouteTransition exact path="/kick-speed">
                    <KickSpeed />
                  </RouteTransition>
                </AnimatedRoutes>
              </AnalyticsWrapper>
            </Router>
          </div>
        </div>
      </main>
    </HelmetProvider>
  );
};

export default CreationFlow;
