import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import Markdown from "react-markdown";

import { useCreationDispatch, useCreationState } from "../creationFlowHooks";

import Layout from "../shared/Layout";
import Hero from "../shared/Hero";
import CustomerDetailsForm from "./CustomerDetailsForm";
import { termsText } from "../splash/terms";
import Button from "../shared/Button";
import { setUser } from "../creationFlowActions";

const CustomerDetails: React.FC = () => {
  const { error, user } = useCreationState();
  const history = useHistory();
  const dispatch = useCreationDispatch();
  const [termsModalOpen, setTermsModalOpen] = React.useState(false);
  const onOpenTermsModal = () => setTermsModalOpen(true);
  const onCloseTermsModal = () => setTermsModalOpen(false);
  const userDetailsFormRef = React.useRef<HTMLFormElement>();
  React.useEffect(() => {
    if (error) {
      history.replace("/");
      toast.error(error);
    }
  }, [error, history]);

  React.useEffect(() => {
    if (user) {
      // const emailed = localStorage.getItem("emailed");
      // const alreadyEmailed = emailed ? JSON.parse(emailed) : [];
      // alreadyEmailed.push({ path: photoPath, added: new Date() });
      // localStorage.setItem("emailed", JSON.stringify(alreadyEmailed));
      history.replace("/enter");
    }
    // eslint-disable-next-line
  }, [user]);
  const onNext = () => {
    if (userDetailsFormRef && userDetailsFormRef.current) {
      userDetailsFormRef.current.requestSubmit();
    }    
  }
  const onUserDetails = (user) => {
    dispatch(setUser({
      firstName: user.firstName,
      lastName: user.lastName,
      emailAddress: user.email,
      marketingOptIn: false
    }));
  }
  return (
    <Layout backgroundClass="bg-gray-50">
      <Hero role="DETAILS" />
      <div className="flex flex-col flex-1 items-center mx-5 overflow-hidden h-full">
        <Modal
          open={termsModalOpen}
          onClose={onCloseTermsModal}
          center
          styles={{
            modal: {
              height: "unset",
              minHeight: "20rem",
              width: "80%",
            },
          }}
          classNames={{ modal:"!bg-white"}}
        >
          <div className="p-6 antialiased font-body bg-white">
            <Markdown>{termsText}</Markdown>
          </div>
        </Modal>
        <div className="mt-7">
          <h1 className="text-sm font-bold text-center">
            ENTER YOUR DETAILS
          </h1>
          <h1 className="text-sm font-bold text-center">
            TO BE IN TO WIN!
          </h1>
        </div>
        <div className="flex flex-grow overflow-y-scroll mt-4 mb-4 w-full rounded-lg">
          <CustomerDetailsForm
            onSubmit={onUserDetails}
            openTermsModal={onOpenTermsModal}
            formRef={userDetailsFormRef}
          />
        </div>        
      </div>
      <div className="flex items-end justify-center py-2 bg-black">
        <div className="flex ">
        <Button
          theme="secondary"
          className="self-end"
          onClick={onNext}
        >
          NEXT
        </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CustomerDetails;
