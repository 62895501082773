import * as React from "react";

import Layout from "../shared/Layout";
import Hero from "../shared/Hero";

const SuccessScreen: React.FC = () => {
  const basepath = window.location.pathname.split("/")[1];
  setTimeout(() => {
    window.location.href = `${window.location.origin}/${basepath}`;
  }, 2000);
  return (
    <Layout>
      <Hero role="SUCSSES" />
      <div className="flex flex-col flex-1 items-center mx-5 overflow-hidden h-full">
        <div className="mt-7">
          <h1 className="text-2xl font-bold text-center">
            ALL DONE!
          </h1>
          <h1 className="text-sm font-bold text-center pt-8 pb-4">
            Your entry is submitted
          </h1>
          <h1 className="text-sm font-bold text-center">
            Look out for your score on the screen above
          </h1>
        </div>
      </div>
    </Layout>
  );
};

export default SuccessScreen;
