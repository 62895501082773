export const basepath = window.location.pathname.split("/")[1];

const events = {
    "testing": {   
        eventId: "4542b4d7-319f-4bb3-992b-5c81b25d97ca",
        questionId: "2f87f4c4-3bf9-4139-9147-82aaede6a466",
    },
    "game1": {
        eventId: "b29c132b-de8e-4ab5-af3c-8a8dec45a9fe",
        questionId: "4dad277a-7b91-44fa-bc06-7eadb22110cb"
    },
    "game2": {
        eventId: "347972a3-0a97-406c-bcdd-d1912b332718",
        questionId: "254d062e-cc54-4a06-b2cb-569711879fa3"
    },
    "game3": {
        eventId: "d4caeed2-186c-4434-a5e1-7963472f22cf",
        questionId: "d2abfa4f-5c5f-40d2-9077-6db85f283330"
    },
    "game4": {
        eventId: "ef42af2e-e41b-4c4b-9f9b-8a39ab437fb3",
        questionId: "5be50e55-0b25-49ce-b210-b51ead32cad5"
    },
    "game5": {
        eventId: "6a6e38d8-edfc-43c7-94fd-271176cf871c",
        questionId: "82daa26e-2ad3-4f5f-83c0-03439d8b65da"
    },
    "game6": {
        eventId: "8a944cca-d9ad-4fc7-9622-0ad231481a1f",
        questionId: "f1640afc-23c9-4ea5-b6ea-2701da55d1f3"
    }
};

export const isValidEvent = (basepath: string): boolean => {
    if (!basepath) {
        return false;
    }

    return events[basepath.toLowerCase()] !== undefined;
}

export const eventDetails = (basepath: string): { eventId: string, questionId: string} => {
    if (!isValidEvent(basepath)) {
        return undefined;
    }
    return events[basepath.toLowerCase()];
}