import React from "react";
import "./styles/index.css";
import { CreationContextProvider } from "./CreationContext";
import CreationFlow from "./CreationFlow";
import { basepath, isValidEvent } from "./common/common";
import MobileContainer from "./shared/MobileContainer";

const calculateVH = (): void => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

// Disable annoying system zoom on iOS
document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
});

window.addEventListener("resize", () => {
  calculateVH();
});

calculateVH();

const App: React.FC = () => {
  console.log(basepath);
  if (!isValidEvent(basepath)) {
    return ( 
      <MobileContainer>
        <div>
          <h1>Please choose a valid event.</h1>
        </div>
      </MobileContainer>
    );
  }
  return (
    <CreationContextProvider>
      <CreationFlow />
    </CreationContextProvider>
  );
};

export default App;
