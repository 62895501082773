import { CreationActions, CustomerDetails, User } from "./types";

export enum Types {
  SetCustomerDetails = "SET_CUSTOMER_DETAILS",
  SetUser = "SET_USER",
  SetPhotoPath = "SET_PhotoPath",
}

export const setUser = (user: User): CreationActions => ({
  type: Types.SetUser,
  payload: {
    user: user,
  },
});
export const setPhotoPath = (photoPath: string): CreationActions => ({
  type: Types.SetPhotoPath,
  payload: {
    photoPath: photoPath,
  },
});

export const setCustomerDetails = (
  customerDetails: CustomerDetails
): CreationActions => ({
  type: Types.SetCustomerDetails,
  payload: {
    customerDetails,
  },
});
