import * as React from "react";
import { useForm } from "react-hook-form";

import Input from "../shared/Input";
import { isDev } from "../enviromentHelper";

interface Props {
  onSubmit: (data: any) => void;
  openTermsModal: () => void;
  formRef: React.MutableRefObject<HTMLFormElement>
}
const CustomerDetailsForm: React.ForwardRefRenderFunction<
  HTMLFormElement,
  Props
> = ({ onSubmit, openTermsModal, formRef }) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: isDev
      ? {
          firstName: "",
          lastName: "",
          email: "",
          termsAccepted: false,
          marketingOptIn: false,
        }
      : {
          firstName: "",
          lastName: "",
          email: "",
          termsAccepted: false,
          marketingOptIn: false,
        },
  });
  
  return (
    <form
      className="flex flex-1 flex-col rounded-lg px-5 pt-7 pb-7 mb-2 bg-white"
      onSubmit={handleSubmit(onSubmit)}
      ref={formRef}
    >
      <div className="mb-4">
        <Input
          error={errors.firstName}
          name="firstName"
          label="First Name"
          register={register({ required: true })}
        />
      </div>

      <div className="mb-4">
        <Input
          error={errors.lastName}
          name="lastName"
          label="Last Name"
          register={register({ required: true })}
        />
      </div>

      <div className="mb-4">
        <Input
          name="email"
          type="email"
          error={errors.email}
          label="Email Address"
          register={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "invalid email address",
            },
          })}
        />
      </div>
      <div className="mx-3 mt-3 mb-5">
        <div className="flex items-center mb-2">
          
        </div>

        <div className="mb-2 text-label text-danger">
          {errors.termsAccepted && "Please agree to the terms and conditions"}
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            name="termsAccepted"
            className="flex-grow-0 mr-3"
            ref={register({ required: true })}
          />

          <label
            htmlFor="termsAccepted"
            className="flex-1 block text-base text-xs"
          >
            Yes, I agree to the <button
            className="text-xs font-bold underline"
            onClick={openTermsModal}
            type="button"
          >
           terms and conditions
            </button> of this promotion
          </label>
        </div>
      </div>
    </form>
  );
};

export default CustomerDetailsForm;
