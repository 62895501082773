import React from "react";
import logger from "use-reducer-logger";

import { CreationStore, CreationState, CreationActions } from "./types";
import { Types } from "./creationFlowActions";
import { isDev } from "./enviromentHelper";

const initialState = {
  user: null,
  entry: null,
  loading: false,
  error: null,
};

export const CreationContext = React.createContext<CreationStore>({
  state: initialState,
  dispatch: () => null,
});


const reducer = (
  state: CreationState,
  action: CreationActions
): CreationState => {
  switch (action.type) {
    case Types.SetCustomerDetails:
      return {
        ...state,
        ...action.payload,
      };
    case Types.SetUser:
    case Types.SetPhotoPath:
      return {
        ...state,
        ...action.payload,
      };

    default:
      throw new Error();
  }
};

interface Props {
  children: JSX.Element;
}

export const CreationContextProvider: React.SFC<Props> = (props: Props) => {
  const reducerToUse = isDev ? logger(reducer) : reducer;
  const [state, dispatch] = React.useReducer(reducerToUse, initialState);

  return (
    <CreationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CreationContext.Provider>
  );
};
