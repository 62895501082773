import React from "react";


interface Props {
  backgroundClass?: string | undefined;
  children: JSX.Element | JSX.Element[];
}
const Layout: React.FC<Props> = ({ backgroundClass, children }: Props) => {
  const backGround = backgroundClass ?? "bg-white";
  const classes = `flex flex-col flex-1 ${backGround} overflow-hidden relative`;
  return (
    <div className={classes}>
      {children}
    </div>
  );
};

export default Layout;
