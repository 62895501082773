import * as React from "react";

import Api, { Entry, User } from "../api";
import { useCreationDispatch } from "../creationFlowHooks";
import { setUser } from "../creationFlowActions";

export const useSubmitUser = () => {
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useCreationDispatch();
  const submitUser = async (userDetails: User): Promise<void> => {
    setLoading(true);
    //TODO: error handling
    const user = await Api.newUser(userDetails);
    if (user) {
      dispatch(setUser({id: user.userId}));
    }
    setLoading(false);
    setSubmitted(true);
  };
  return { loading, submitted, submitUser };
};

export const useSubmitEntry = () => {
  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const submitEntry = async (userDetails: Entry): Promise<void> => {
    setLoading(true);
    //TODO: error handling
    const entry = await Api.saveEntry(userDetails);
    console.log(JSON.stringify(entry));

    setLoading(false);
    setSubmitted(true);
  };
  return { loading, submitted, submitEntry };
};

