import * as React from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";

import { useCreationState } from "../creationFlowHooks";
import Layout from "../shared/Layout";
import Hero from "../shared/Hero";
import Button from "../shared/Button";
import { useSubmitUser } from "../poster/posterHooks";

const MarketingOptIn: React.FC = () => {
  const { error, user } = useCreationState();
  const history = useHistory();
  const { loading, submitted, submitUser } = useSubmitUser();
  const [optIn, setOptIn] = useState(true);

  React.useEffect(() => {
    if (error) {
      history.replace("/");
      toast.error(error);
    }
  }, [error, history]);

  const onSubmitEntry = () => {
    submitUser({
      emailAddress: user.emailAddress,
      firstName: user.firstName,
      lastName: user.lastName,
      marketingOptIn: optIn
    });
  }

  React.useEffect(() => {
    if (submitted) {
      history.replace("/kick-speed");
    }
  }, [submitted, user]);

  return (
    <Layout>
      <Hero role="ENTERSPEED" />
      <div className="flex flex-col flex-1 items-center mx-5 overflow-hidden h-full">
        <div className="mt-7">
          <h1 className="text-sm font-bold text-center">
            WOULD YOU LIKE TO
          </h1>
          <h1 className="text-sm font-bold text-center">
            RECEIVE EMAILS FROM OPPO?
          </h1>
        </div>
        <div className="flex flex-grow pb-4 mt-4 w-full rounded-lg">
          <div className="place-items-center flex flex-1 flex-col rounded-lg px-5 pt-7 pb-7 mb-2 bg-gray-50 overflow-y-auto">
            <h1 className="text-sm font-bold text-center pb-7">
              You must sign up to receive OPPO emails to be in to win
            </h1>
            <div className="flex">
                <Button
                  theme="secondary"
                  className={optIn ? "mr-4 border-black border-2" : "mr-4"}
                  onClick={() => setOptIn(true)}
                >
                  YES
                </Button> <Button
                  theme="secondary"
                  className={optIn ? "ml-4" : "ml-4 border-black border-2"}
                  onClick={() => setOptIn(false)}
                >
                  NO
                </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-end justify-center py-2 bg-black">
        <div className="flex">
        <Button
          theme="secondary"
          className="self-end"
          onClick={onSubmitEntry}
          isLoading={loading}
          disabled={loading}
        >
          NEXT
        </Button>
        </div>
      </div>
    </Layout>
  );
};

export default MarketingOptIn;
